<template>
  <v-autocomplete
    prepend-icon="business"
    v-model="model"
    :items="organizations"
    item-value="uid"
    item-text="name"
    :label="label ? label : $t('organizations.title')"
    :loading="loading"
    @change="_emit('change', $event)"
    @input="_emit('input', $event)"
    clearable
  >
  </v-autocomplete>
</template>

<script lang="js">
export default {
  props: {
    uid: { type: String, required: false, default: undefined},
    organization: { type: Object, required: false, default: undefined},
    prependIcon: { type: String},
    label: {type: String},
    loading: {type: Boolean, default: false}
  },
  data() {
    return {
      model: this.organization ? this.organization.uid : this.uid
    }
  },
  computed: {
    organizations() { return this.$store.getters["organizations/get"](); }
  },
  watch: {
    uid(uid) {
        this.model = uid;
    },
    organization(organization) {
      this.model = organization ? organization.uid : undefined;
    }
  },
  created() {
    this.$store.dispatch("organizations/list");
  },
  methods: {
    _emit(name, $event) {
      if (typeof $event === 'string') {
        this.$emit(name, this.organizations.find(o => o.uid === $event))
      } else {
        this.$emit(name, $event)
      }
    }
  }
}
</script>
