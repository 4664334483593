<template>
  <v-dialog
    v-model="dialog"
    @input="$emit('show', $event)"
    :width="width"
    @keydown:esc="$emit('show', false)"
  >
    <v-form @submit.stop.prevent="$emit('input', input)">
      <v-card>
        <v-card-title>{{ title }}</v-card-title>
        <v-card-text>
          {{ text }}
          <v-select
            :items="items"
            :label="title"
            v-model="input"
            :item-text="itemText"
            :item-value="itemValue"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn type="submit">
            <v-icon>done</v-icon>
          </v-btn>
          <v-btn @click="$emit('show', false)">
            <v-icon>cancel</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: { required: false },
    title: { type: String },
    text: { type: String },
    show: { type: Boolean },
    width: { default: "50%" },
    items: { required: true },
    itemText: { required: false, default: "text" },
    itemValue: { required: false, default: "value" }
  },
  data() {
    return {
      input: this.value,
      dialog: this.show
    };
  },
  watch: {
    value() {
      this.input = this.value;
    },
    show() {
      this.input = this.value;
      this.dialog = this.show;
    }
  }
};
</script>

<style></style>
